.cash {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.cash .input {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
}

.cash .select {
    padding: 10px;
    margin-top: 15px;
}
