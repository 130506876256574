.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.form .input {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
}

.form .select {
    padding: 10px;
    margin-top: 15px;
}
