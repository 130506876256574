.sendItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.sendItem .input {
    width: 100%;
    padding: 10px;
    /*margin-top: 15px;*/
}

.sendItem .select {
    padding: 10px;
    /*margin-top: 15px;*/
}
